@import "../../Dimens";
@import "../../Colors";

.add-bit-floating {
  position: fixed;
  bottom: 2em;
  right: 50%;
  margin-right: -1.5em;
  z-index: 17;

  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;

  border-radius: 50%;
  background: $blue-light;
  color: $cloud-light;
  font-size: $font-big;

  transition: all 140ms ease-out;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: $grey-125 0 4px 4px;
    background: $blue-lighter;
  }

  &.hidden {
    display: none;
  }
}
