@import "src/components/Colors";
@import "src/components/Dimens";

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Rubik", sans-serif;
  font-size: $font-normal;
  line-height: 1.5;
  background: $background-light;
  color: $text-default;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.foobar {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  padding: 0 0 0 $sidebar-width;
}

@media (max-width: 1200px) {
  .content {
    padding: 0;
  }
}

a {
  color: $text-light;
  text-decoration: none;

  &.blue {
    color: $blue-light;
  }

  &:hover {
    color: $blue-dark;
  }
}

.page-content {
  margin-top: 4em;
  margin-bottom: 12em;
}

@media (max-width: 768px) {
  .page-content {
    margin-top: 2em;
    margin-bottom: 6em;
  }
}

h1 {
  font-size: $font-large;
  font-weight: 500;
  margin-top: 0;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: 500;
}

hr.section-separator {
  margin: 2em 0;
}

form {
  .input-wrapper {
    display: flex;
    margin-top: 1em;

    input {
      margin-left: 0.5em;

      &:first-child {
        margin-left: 0;
      }
    }

    &.multiline {
      flex-direction: column;

      input {
        margin-left: 0;
        margin-top: 1em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

button[type="submit"],
input[type="submit"],
.button-default {
  font-size: $font-normal;
  padding: 0.5em 1em;
  background: $blue-light;
  color: $cloud-light;
  border: $blue-light solid 1px;
  border-radius: $border-radius-small;
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: $blue-lighter;
  }

  &:focus {
    outline: none;
  }
}

button[type="submit"] {
  padding: 0.25em 1em;
  text-align: center;
}

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  width: 100%;
  min-height: 36px;
  color: $text-default;
  border: $grey-125 solid 1px;
  padding: 0.5em 1em;
  border-radius: $border-radius-small;

  &:hover {
    border-color: $grey-25;
  }

  &:focus {
    border-color: $grey-375;
    outline: none;
  }
}

input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  margin: 0;

  max-width: 24px;
  min-width: 24px;

  max-height: 24px;
  min-height: 24px;

  border: 2px solid $text-default;
  border-radius: $border-radius-small;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  &::before {
    content: "";

    width: 16px;
    height: 16px;

    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

    background: $blue-light;
    border-radius: $border-radius-tiny;
  }

  &:checked::before {
    transform: scale(1);
  }
}

.submit-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 0.5em;
}

.form-warning,
.form-error {
  padding: 0.5em 1em;
  font-size: $font-small;
  font-weight: 500;
  color: $white-90;
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: $grey-25 4px 4px 8px;
  animation: animErrorAppear 200ms;

  &.big {
    padding: 1em 2em;
    bottom: -6em;
  }
}

.form-warning {
  background: $sunflower-light;
  border: $sunflower-dark solid 2px;
  border-radius: $border-radius-normal;
}

.form-error {
  background: $grapefruit-light;
  border: $grapefruit-dark solid 2px;
  border-radius: $border-radius-normal;
}

.userBox {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .user {
    padding: 0;
    text-align: center;

    h3 {
      margin: 0.25em 0 0 0;
      font-size: $font-normal;
      font-weight: 500;
    }
  }

  .bit-list {
    border-left: $grey-10 solid 1px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 2em;
  }
}

@media (max-width: 768px) {
  .userBox .bit-list {
    border-left: none;
  }
}

::selection {
  background: $blue-light;
  color: $white-90;
}
