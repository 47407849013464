@import "src/components/Colors";
@import "src/components/Dimens";

.month-separator {
  padding: 1em;
  text-align: center;

  color: $text-hint;
  font-size: $font-big;
  font-style: italic;
}
