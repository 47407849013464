@import "../../Colors";
@import "../../Dimens";

nav.topNavigationBar {
  display: none;
  padding: 0 0.5em;
}

@media (max-width: 1200px) {
  nav.topNavigationBar {
    width: 100%;

    z-index: 1;
    top: 0;
    left: 0;
    right: 0;

    background: $background-dark;
    color: $text-muted;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

nav.topNavigationBar {
  div.navigation-hero {
    text-align: center;
    color: $grapefruit-dark;

    img {
      width: 64px;
      height: 64px;
      display: inline-block;
    }
  }

  .profile-box {
    display: flex;
    align-items: center;
  }

  .navigation-options-sub-wrapper {
    display: flex;
    align-items: center;
  }

  ul.navigation-options,
  ul.optionsSub {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    li {
      display: inline-block;
    }
  }
}

@media (max-width: 600px) {
  ul.navigation-options,
  ul.optionsSub {
    li a {
      padding: 0.75em;
    }
  }

  .navigation-option-name {
    display: none;
  }
}
