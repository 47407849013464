$sidebar-width: 12em;

$border-radius-big: 16px;
$border-radius-normal: 8px;
$border-radius-small: 4px;
$border-radius-tiny: 2px;

$font-tiny: 12px;
$font-small: 14px;
$font-normal: 16px;
$font-big: 19px;
$font-large: 24px;