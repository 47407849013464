@import "../../Colors";
@import "../../Dimens";

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-details-wrapper {
    margin: 4em 0;
    text-align: center;

    .profile-username {
      margin-top: 1em;
      font-size: $font-big;
      font-weight: 500;
    }

    .profile-email {
      color: $text-light;
    }
  }

  .create-team {
    margin-top: 2em;
  }

  .profile-logout {
    margin: 0 0 4em 0;
  }
}
