@import "../../Colors";
@import "../../Dimens";

.taskList {
  margin: 0;
  padding: 0;
  list-style: none;

  &.items-loose .task {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }

  &.taskList-dragging-over {
    background: $grey-0375;
  }
}
