@import "../../Colors";
@import "../../Dimens";
@import "../../Anims";

.task-reaction-tooltip {
  .tooltip-inner {
    font-family: "Rubik", sans-serif;
    font-size: $font-small;
    color: $cloud-dark;
    padding: 0.5em 1em;
  }
}

.taskList {
  min-height: 5.5em;

  &.notebook {
    min-height: 2.75em;
  }

  .task {
    padding: 0.25em 0.5em 0.25em 0;
    display: flex;
    background: $background-light;

    &.task-highlight {
      animation: animHighlightItemYellow 1500ms;
    }

    &.task-highlight-green {
      animation: animHighlightItemGreen 1500ms;
    }

    &.task-dragged {
      background: $white-90;
      border: $grey-025 solid 1px;
      border-radius: $border-radius-normal;
      box-shadow: $grey-125 4px 4px 8px;
    }

    .task-drag-handle {
      display: inline-block;
      user-select: none;
      visibility: hidden;

      i {
        display: inline-block;
        width: 1.5em;
        height: 2.25em;
        line-height: 2.25em;
        color: $text-hint;
      }
    }

    .icon {
      display: inline-block;
      margin: 0.5em 0;
    }

    .task-content-wrapper {
      display: inline-block;
      align-self: center;
      margin-left: 0.5em;
      line-height: 1.5em;
      flex-grow: 1;

      .task-decoration {
        font-size: $font-tiny;
        color: $text-light;
        line-height: 1em;
        margin: 0 0 0.25em 0;
      }

      .task-content {
        position: relative;
        padding-right: 8em;

        .task-edit-area {
          display: flex;
          flex-direction: column;

          textarea.textarea-bit {
            margin: 0;
            padding: 0;
            color: $text-default;
            width: 100%;
            outline: none;
            resize: none;

            line-height: 1.4;

            background: transparent;
            border: none;
          }

          .task-editing-hint {
            font-size: $font-tiny;
            color: $text-light;
            line-height: 1em;
            margin: 0.25em 0 0 0;

            .bold {
              color: $aqua-dark;
            }
          }
        }
      }
    }

    &:hover {
      background: $grey-025;
    }

    &.task-draggable:hover .task-drag-handle {
      visibility: visible;
    }
  }
}

@media (max-width: 1200px) {
  .taskList .task {
    padding: 0.25em 0.5em;

    .task-content-wrapper {
      margin-left: 0;

      .task-content {
        padding-right: 4em;
      }
    }
  }
}

@media (max-width: 768px) {
  .taskList {
    margin-top: 1em;

    .task .task-content-wrapper .task-content {
      padding-right: 2em;
    }
  }
}
