@import "../../Colors";
@import "../../Dimens";

ul.login-options {
  margin: 1.5em 0 0 0;
  padding: 0;
  list-style: none;

  color: $text-muted;
  text-align: center;

  li {
    margin-top: 0.5em;
  }

  a {
    color: $blue-light;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: $blue-dark;
    }
  }

  i {
    color: $blue-light;
    font-size: 3px;
    margin: 0 12px;
  }
}
