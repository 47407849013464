@import "../../Colors";
@import "../../Dimens";

.pageHeaderWrapper {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 99;

  background: $background-light;
  box-shadow: $grey-025 0 4px 8px;
}

@media (max-width: 1200px) {
  .pageHeaderWrapper:first-child {
    margin-left: 0;
  }
}

.pageHeader {
  border-bottom: $grey-10 solid 1px;
  min-height: 64px;

  margin: 0;
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .pageHeaderTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      min-width: 23em;
      font-size: $font-big;
      font-weight: 500;
      text-align: center;
    }

    h2 {
      margin: 0.25em 0 0 0;
      font-size: $font-tiny;
      font-weight: 300;
      text-align: center;
      color: $text-muted;
    }
  }

  ul.pageOptions {
    margin: 0;
    padding: 0.25em;
    background: $white;
    border: $grey-10 solid 1px;
    border-radius: $border-radius-normal;

    li {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin-left: 0.125em;

      &:first-child {
        margin-left: 0;
      }

      a {
        display: inline-block;
        font-size: $font-big;
        width: 2em;
        height: 2em;
        border: transparent solid 1px;
        border-radius: $border-radius-small;
        text-align: center;
        color: $tarmac-dark;
        text-decoration: none;
        line-height: 2em;

        &:hover {
          background: $grey-075;
          border-color: $grey-05;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .pageHeader {
    flex-direction: column;

    ul.pageOptions {
      margin-top: 0.5em;

      li {
        margin-left: 1em;
      }
    }
  }
}
