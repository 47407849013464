@import "Colors";
@import "Dimens";

.text-highlight-done {
  color: $grass-dark;
  font-weight: 500;
}

.text-highlight-plan {
  color: $sunflower-dark;
  font-weight: 500;
}

.text-highlight-pending {
  color: $aqua-dark;
  font-weight: 500;
}

.text-highlight-blocker {
  color: $grapefruit-dark;
  font-weight: 500;
}

.dmb-tooltip {
  text-align: center;

  .tooltip-inner {
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: $font-small;
    color: $cloud-dark;
    padding: 0.5em 1em;
  }
}
