@import "../../Colors";
@import "../../Dimens";

.new-bit-floating-panel-wrapper {
  &.hidden {
    visibility: hidden;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 18;

  display: flex;
  justify-content: center;

  .new-bit-floating-panel {
    padding: 0.25em;
    margin: 0 1em 4em 1em;

    min-width: 50%;
    box-shadow: $grey-125 0 4px 8px;

    background: $white;
    border: $grey-10 solid 1px;
    border-radius: $border-radius-normal;
  }
}

@media (max-width: 768px) {
  .new-bit-floating-panel-wrapper .new-bit-floating-panel {
    margin-bottom: 2em;
  }
}
