@import "../../Colors";
@import "../../Dimens";

.placeholder-empty {
  display: flex;
  align-items: center;
  flex-direction: column;

  background: $white;
  padding: 2em 8em;
  border-radius: $border-radius-big;
  border: $grey-075 solid 1px;

  img {
    width: 192px;
    height: 192px;
    margin-bottom: 1.5em;
  }

  p {
    margin-bottom: 0;
    text-align: center;

    &.placeholder-title {
      font-weight: 500;
      font-size: $font-big;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  i.fa-star {
    color: $sunflower-dark;
  }
}

@media (max-width: 768px) {
  .placeholder-empty {
    padding: 1em;

    img {
      width: 128px;
      height: 128px;
      margin-bottom: 1em;
    }
  }
}
