@import "../../Colors";

.legal-wrapper {
  display: flex;
  align-items: center;

  margin-top: 1em;

  label {
    margin-left: 1em;
  }
}
