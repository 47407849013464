@import "../../Colors";
@import "../../Dimens";

.weekly-overdue-warning-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 0 2em 0;

  .weekly-overdue-warning {
    background: $grapefruit-lightest;
    border: $grapefruit-lighter solid 1px;
    border-radius: $border-radius-normal;
    padding: 1em 2em;
    display: inline-block;

    i.fa-calendar-day {
      color: $grapefruit-dark;
      margin-right: 0.5em;
    }
  }
}
