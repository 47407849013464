@import "../../Anims";
@import "../../Colors";
@import "../../Dimens";

.priority-icon {
  display: inline-block;
  user-select: none;

  i {
    display: inline-block;
    width: 2.25em;
    height: 2.25em;
    line-height: 2.25em;
    color: $text-hint;
    transition: all 400ms;
    transform: translateY(-0.05em);

    &:hover {
      cursor: pointer;
      color: $sunflower-dark;
    }

    &.fas.fa-star {
      color: $sunflower-dark;
    }

    &.new-priority {
      animation: animNewPriority 400ms;
    }
  }
}
