@import "../../Colors";
@import "../../Dimens";

nav.sidebar {
  height: 100%;
  width: $sidebar-width;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  box-sizing: border-box;

  background: $background-dark;
  color: $text-muted;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  nav.sidebar {
    display: none;
  }
}

nav.sidebar {
  div.navigation-hero {
    text-align: center;

    img {
      width: $sidebar-width;
      height: $sidebar-width;
      display: inline-block;
    }
  }

  .navigation-options-sub-wrapper {
    height: $sidebar-width;
    display: flex;
    align-items: flex-end;
  }

  .profile-box {
    display: flex;
    align-items: center;

    i.fas {
      margin-right: 0.5em;
    }
  }

  ul.optionsSub {
    border-top: rgba(255, 255, 255, 0.1) solid 1px;
  }

  ul.navigation-options,
  ul.optionsSub {
    padding: 0;
    margin: 0;
    width: $sidebar-width;
  }
}
