@import "../../Colors";
@import "../../Dimens";

.user-avatar {
  display: inline-block;
  font-size: $font-large;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  width: 2em;
  height: 2em;
  line-height: 2em;
  background: $blue-light;
  color: $cloud-light;
  border-radius: $border-radius-tiny;

  &.size-big {
    font-size: 48px;
    border-radius: $border-radius-small;
  }

  &.size-medium {
    font-size: $font-big;
  }
}
