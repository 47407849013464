$brand-blue: #51b3db;

$cloud-light: #f5f7fa;
$cloud-dark: #e6e9ed;
$tarmac-light: #656d78;
$tarmac-dark: #434a54;

$aqua-light: #4fc1e9;
$aqua-dark: #3bafda;
$grass-light: #a0d468;
$grass-dark: #8cc152;
$mint-light: #48cfad;
$mint-dark: #37bc9c;
$sunflower-light: #ffce54;
$sunflower-dark: #f6bb42;
$grapefruit-light: #ed5565;
$grapefruit-dark: #da4453;
$lavender-light: #ac92ec;
$lavender-dark: #967adc;

$blue-dark: #008ac4;
$blue-light: #00b6df;
$green-dark: #01a466;
$green-light: #89c764;
$red-dark: #d01238;
$red-light: #eb4a62;
$yellow-dark: #f8a618;
$yellow-light: #ffca08;

$background-dark-alt: #1c2224;
$background-dark: #1b2224;
$background-light: #fbfbfb;

$text-hint: #a9b1b5;
$text-light: #899195;
$text-muted: #637074;
$text-default: $tarmac-dark;

$grey-025: rgba(0, 0, 0, 0.025);
$grey-0375: rgba(0, 0, 0, 0.0375);
$grey-05: rgba(0, 0, 0, 0.05);
$grey-075: rgba(0, 0, 0, 0.075);
$grey-10: rgba(0, 0, 0, 0.1);
$grey-125: rgba(0, 0, 0, 0.125);
$grey-25: rgba(0, 0, 0, 0.25);
$grey-375: rgba(0, 0, 0, 0.375);
$grey-50: rgba(0, 0, 0, 0.5);
$grey-75: rgba(0, 0, 0, 0.75);

$white-90: rgba(255, 255, 255, 0.9);
$white: #ffffff;

$blue-lighter: #19bde2;
$blue-lightest: #e1f3f7;
$yellow-ultralight: hsl(47, 100%, 94%);
$green-ultralight: hsl(98, 47%, 89%);

$grapefruit-lightest: #fcebec;
$grapefruit-lighter: #ffdee1;
