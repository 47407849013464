@import "../../Colors.scss";
@import "../../Dimens";

.icon {
  display: inline-block;
  user-select: none;

  &.tiny {
    font-size: 8px;
  }

  &.medium {
    font-size: 11px;
  }

  i {
    border-radius: 50%;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    &.status-done {
      background: $grass-light;
      border: $grass-dark solid 1px;

      &.new {
        animation: animBitDone 400ms;
      }
    }

    &.status-pending {
      background: $grey-05;
      border: $grey-10 solid 1px;

      &:hover {
        background: $grey-10;
      }
    }

    &.status-done,
    &.status-pending {
      width: 2.25em;
      height: 2.25em;
      line-height: 2.25em;
      color: $cloud-light;
    }
  }
}
