@import "./Colors";

@keyframes animHighlightItemYellow {
  0% {
    background: $yellow-ultralight;
  }
  50% {
    background: $yellow-ultralight;
  }
  100% {
    background: inherit;
  }
}

@keyframes animHighlightItemGreen {
  0% {
    background: $green-ultralight;
  }
  50% {
    background: $green-ultralight;
  }
  100% {
    background: inherit;
  }
}

@keyframes animErrorAppear {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(2em);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes animFlashAppear {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(4em);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes animBitDone {
  25% {
    background: $cloud-light;
    transform: scale(0.87, 0.87);
  }

  65% {
    transform: scale(1.25, 1.25);
  }

  90% {
    transform: scale(0.87, 0.87);
    background: $grass-light;
  }

  100% {
    background: $grass-light;
    transform: scale(1, 1);
  }
}

@keyframes animLogoAppear {
  0% {
    transform-origin: 50% 0;
    transform: scale(1.25);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes animNewPriority {
  25% {
    color: $cloud-light;
    transform: scale(0.87, 0.87);
  }

  65% {
    transform: scale(1.25, 1.25);
  }

  90% {
    transform: scale(0.87, 0.87);
    color: $sunflower-dark;
  }

  100% {
    color: $sunflower-dark;
    transform: scale(1, 1);
  }
}
