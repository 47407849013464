@import "../../Colors";
@import "../../Dimens";

form.new-task {
  display: flex;
  flex-direction: column;
  padding: 1em 1em;

  .task-essentials {
    display: flex;
    width: 100%;
  }

  ul.task-day-selector {
    margin: 0.25em 0 0 0;
    padding: 0;
    text-align: center;

    li.task-day-option {
      display: inline-block;
      padding: 0.125em 0.75em;
      margin-top: 0.25em;
      margin-left: 0.5em;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: $white-90;
      background: $grey-125;
      border-radius: 8px;
      font-size: $font-small;

      &:hover {
        background: $grey-25;
        cursor: pointer;
      }

      &.selected {
        font-weight: 500;
        background: $blue-light;
      }

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(6) {
        margin-left: 2em;
      }
    }
  }

  button[type="submit"] {
    opacity: 0.66;
    max-height: 36px;
    min-height: 36px;

    &.ready,
    &:hover {
      opacity: 1;
    }
  }

  .new-bit-note {
    margin: 0.5em 0.5em 0 0.5em;
  }

  .cancel {
    cursor: pointer;

    margin-top: 0.5em;
    color: $blue-light;
    text-decoration: none;

    &:hover {
      color: $blue-dark;
    }
  }

  .icon,
  .task-content {
    display: inline-block;
  }

  .task-content {
    align-self: center;
    margin-left: 0.5em;
    line-height: 1.5em;
    flex-grow: 1;

    input[type="text"] {
      border: none;
      background: $grey-0375;
      padding: 0.5em;
    }
  }

  ::placeholder {
    color: $grey-25;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    color: $text-default;
    border: $grey-125 solid 1px;
    padding: 0.5em 1em;
    border-radius: $border-radius-small;

    &:hover {
      border-color: $grey-25;
    }

    &:focus {
      border-color: $grey-25;
      outline: none;
    }
  }
}
