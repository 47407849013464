@import "../../Colors";
@import "../../Dimens";
@import "../../Anims";

.task {

  .task-options {
    visibility: hidden;
  }

  &:hover .task-options {
    visibility: inherit;
  }
}

.task-options {
  font-size: $font-big;
  color: $text-hint;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  z-index: 9;

  ul {
    padding: 0.25em;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      list-style: none;

      width: 2em;
      height: 2em;
      line-height: 2em;
      text-align: center;

      &:hover {
        cursor: pointer;
        color: $text-default;
      }
    }
  }
}
