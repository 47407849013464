@import "../../Colors";
@import "../../Dimens";

ul.navigation-options,
ul.optionsSub {
  li {
    list-style: none;

    .navigation-option-name {
      margin-left: 0.25em;
    }

    a {
      display: block;
      color: $tarmac-light;
      text-decoration: none;
      padding: 0.75em 1em;

      i {
        opacity: 0.33;

        &.fa-home {
          color: $blue-light;
        }

        &.fa-star {
          color: $yellow-light;
        }

        &.fa-calendar-day {
          color: $red-light;
        }

        &.fa-book {
          color: $green-dark;
        }
      }

      &:hover {
        color: $cloud-dark;

        i {
          opacity: 0.66;
        }
      }

      &.active {
        color: $cloud-light;

        i {
          opacity: 1;
        }
      }
    }
  }
}
