@import "../../Colors";
@import "../../Dimens";

#flash-root {
  position: fixed;
  bottom: 8em;

  &:empty {
    display: none;
  }
}

.flash-message {
  padding: 1em 4em;

  background: $grey-50;
  border: $grey-375 solid 2px;
  border-radius: $border-radius-normal;
  box-shadow: $grey-25 4px 4px 8px;
  animation: animFlashAppear 400ms;

  font-weight: 500;
  color: $white-90;
  text-align: center;
}
