@import "../../Colors";
@import "../../Dimens";

div.section-title {
  border-bottom: $grey-075 solid 1px;
  margin: 4em 0 1em 0;
  padding: 0 0.5em;

  display: flex;

  &:first-child {
    margin-top: 0;
  }

  i.fa-fw {
    display: inline-block;
    font-size: 1rem;

    height: 1.25rem;
    width: 1.25rem;
    line-height: 1.25rem;
    color: $tarmac-dark;

    margin-right: 0.5em;

    &.fa-calendar-day {
      color: $red-light;
    }

    &.fa-home {
      color: $blue-light;
    }
  }

  h2 {
    font-size: 1.25rem;
    display: inline-block;

    margin: 0;
    min-width: 23em;
    font-weight: 500;
  }
}
