@import "../../Colors";
@import "../../Dimens";

.timeline-badge {
  border: transparent solid 1px;
  display: inline-block;
  color: $tarmac-dark;
  padding: 0.25em 1em;

  &.today {
    border-color: $blue-dark;
    background: $blue-light;
    color: $cloud-light;
    border-radius: $border-radius-small;
  }

  .weekday {
    font-size: $font-large;
    font-weight: 500;
    text-transform: uppercase;
  }

  .date {
    font-size: $font-small;

    .strong {
      font-weight: 500;
    }

    .ordinal {
      font-size: $font-tiny;
    }
  }
}
