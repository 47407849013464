@import "../Colors";
@import "../Dimens";

.login-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  background: $background-dark;

  .logo-wrapper {
    flex: 0 0 50%;
    padding: 0 4em;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      animation: animLogoAppear 1750ms ease-in-out;
      height: 256px;
    }
  }

  .login-box {
    flex: 0 0 50%;
    padding: 0 4em;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: $background-light;

    .row {
      width: 100%;
    }

    img {
      width: 192px;
      height: 192px;
      margin-right: 4em;
    }

    .login-flavour {
      color: $text-light;
      text-align: center;
      margin: 1em 0;
    }
  }

  @media (max-width: 1200px) {
    .logo-wrapper {
      padding: 0 2em;
      flex: 0 0 40%;
    }
    .login-box {
      padding: 0 2em;
      flex: 0 0 60%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .logo-wrapper {
      display: none;
      flex: 0 0 0;
    }
    .login-box {
      padding: 2em;
      flex-grow: 1;
    }
  }

  header.login-header {
    display: none;
    min-height: 72px;

    img {
      height: 24px;
    }

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
